// apiService.ts
import axios from 'axios';
import { User, Item, Attribute, Image, ImageSize, Outfit, ArrangedItem } from './models/models';
import { msalInstance } from './msalConfig';
import { AuthenticationResult } from '@azure/msal-browser';
import { getApiServerUrlBase } from './env_config';
import { setUser } from './userState';

// Factory function that accepts the base URL
export const createApiService = (apiServerUrlBase: string) => {
    const axiosInstance = axios.create({
        baseURL: apiServerUrlBase,
        maxRedirects: 1, // Set the maximum number of redirects to follow
    });

    axiosInstance.interceptors.request.use(
        async (config) => {
            try {
                const account = msalInstance.getAllAccounts()[0];
                if (account) {
                    const tokenResponse: AuthenticationResult = await msalInstance.acquireTokenSilent({
                        scopes: ["openid", "profile"],  // Replace with the scopes your API requires
                        account: account,
                    });

                    if (tokenResponse && tokenResponse.idToken) { //todo this really should be accessToken, but it's not coming back in the response
                        config.headers.Authorization = `Bearer ${tokenResponse.idToken}`;
                    } else {
                        console.error('No access token found');
                    }
                } else {
                    console.error('No account found');
                }
            } catch (error) {
                console.error('Error acquiring token silently:', error);
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    const fetchUserModel = async () => {
        try {
            const response = await axiosInstance.get('/api/me/');
            const user = mapUserModelFromJson(response.data);
            return user;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }

    //TODO check local cache for data , if missing force sync otherwise go with async, and update cache
    const initLookupData = async () => {
        await fetchColors();
        await fetchItemTypes();
        await fetchOutfitAttributeCategories();
    }

    const fetchColors = async () => {
        try {
            const response = await axiosInstance.get('/api/attributes/colors/');
            localStorage.setItem('colors', JSON.stringify(response.data));
        } catch (error) {
            console.error('Error fetching outfit attribute categories:', error);
            throw error;
        }
    }

    const fetchOutfitAttributeCategories = async () => {
        try {
            const response = await axiosInstance.get('/api/attributes/outfit_categories/');
            localStorage.setItem('outfit_categories', JSON.stringify(response.data));
            return response.data;
        } catch (error) {
            console.error('Error fetching outfit attribute categories:', error);
            throw error;
        }
    };

    const fetchItemTypes = async () => {
        try {
            const response = await axiosInstance.get('/api/attributes/item_types/');
            localStorage.setItem('itemTypes', JSON.stringify(response.data));
            return response.data;
        } catch (error) {
            console.error('Error fetching item types:', error);
            throw error;
        }
    }

    const mapUserModelFromJson = (jsonData: any): User => {
        const user = new User(
            jsonData.id,
            jsonData.created,
            jsonData.updated,
            jsonData.username,
            jsonData.displayName,
            mapItems(jsonData.items),
            jsonData.outfits.map((outfit: any) => new Outfit(
                outfit.id,
                mapAttributes(outfit.attributes),
                outfit.compositeImageUrl ? apiServerUrlBase + outfit.compositeImageUrl : null,
                mapArrangedItems(jsonData.items, outfit.arrangedItems)
            )),
        );
        return user;
    }

    const mapOutfitItemsFromIds = (jsonItems: Item[], itemIds: string[]): Item[] => {
        let userItems = mapItems(jsonItems);
        return userItems.filter(item => itemIds.includes(item.id));
    }

    const mapArrangedItems = (jsonItems: any[], arrangedItems: any[]): ArrangedItem[] => {
        const itemMap = new Map(jsonItems.map(item => [item.id, item]));

        return arrangedItems.map((arrangedItem: any) => {
            const baseItem = itemMap.get(arrangedItem.itemId);
            if (!baseItem) {
                throw new Error(`Item with id ${arrangedItem.itemId} not found`);
            }

            return {
                ...new Item(
                    baseItem.id,
                    mapAttributes(baseItem.attributes),
                    new Image(mapImageSize(baseItem.image.originalImage))
                ),
                x: arrangedItem.x,
                y: arrangedItem.y,
                zIndex: arrangedItem.zIndex,
                width: arrangedItem.width,
                height: arrangedItem.height,
                rotation: arrangedItem.rotation
            } as ArrangedItem;
        });
    }

    const mapItems = (items: any[]): Item[] => {
        return items.map((item: any) => new Item(
            item.id,
            mapAttributes(item.attributes),
            new Image(mapImageSize(item.image.originalImage))
        ));
    }

    const mapImageSize = (imageSizeJson: any): ImageSize => {
        return new ImageSize(
            imageSizeJson.height,
            imageSizeJson.width,
            apiServerUrlBase + imageSizeJson.url
        );
    }

    const mapAttributes = (attributes: any[]): Attribute[] => {
        return attributes.map((attr: any) => new Attribute(
            attr.key,
            attr.value
        ));
    }

    const preprocessItemImage = async (userId: string, imageBlob: Blob) => {
        const formData = new FormData();
        formData.append('image', imageBlob, 'image.png');

        try {
            const response = await axiosInstance.post(
                `/api/users/${userId}/items/preprocess/`,
                formData
            );
            
            return response.data;
        } catch (error) {
            console.error('Error preprocessing item image:', error);
            throw error;
        }
    };

    const updateItem = async (userId: string, item: Item) => {
        const formData = new FormData();

        item.attributes = [];
        for (const key in item) {
            if (item.hasOwnProperty(key) && key !== 'attributes' && key !== 'image') {
                if (Array.isArray(item[key])) {
                    for (const value in item[key]) {
                        item.attributes.push(new Attribute(key, item[key][value]));
                    }
                } else {
                    item.attributes.push(new Attribute(key, item[key]));
                }
            }
        }
        formData.append('item', JSON.stringify(item));

        // Handle image data from preprocessing or direct camera capture
        if (item.image.originalImage.url.startsWith('blob:') || item.image.originalImage.url.startsWith('data:')) {
            const response = await fetch(item.image.originalImage.url);
            const blob = await response.blob();
            formData.append('image', blob, 'image.png');
        }

        try {
            let response;
            
            // For preprocessed images that are being confirmed
            // We use the confirm endpoint for both new and edited items
            response = await axiosInstance({
                method: 'post',
                url: `/api/users/${userId}/items/confirm/`,
                data: formData
            });

            return response.data;

        } catch (error) {
            console.error(`Error: ${error}`);
            throw error;
        } finally {
            setUser(await apiService.fetchUserModel());
        }
    }

    const getUsers = () => {
        const data = localStorage.getItem('users');
        return data ? JSON.parse(data) : null;
    }

    const getColors = () => {
        const data = localStorage.getItem('colors');
        return data ? JSON.parse(data) : null;
    }

    const getItemTypes = () => {
        const data = localStorage.getItem('itemTypes');
        return data ? JSON.parse(data) : null;
    }

    const getItemSubTypesForItemType = (itemType: string): string[] => {
        const data = localStorage.getItem('itemTypes');
        if (!data) return [];

        const itemTypesData = JSON.parse(data);
        return itemType in itemTypesData ? itemTypesData[itemType] : [];
    }

    const getAllItemTypes = (): string[] => {
        const data = localStorage.getItem('itemTypes');
        if (!data) return [];
        
        const itemTypesData = JSON.parse(data);
        return Object.keys(itemTypesData);
    }

    const getOutfitAttributeCategories = () => {
        const data = localStorage.getItem('outfit_categories');
        return data ? JSON.parse(data) : null;
    }

    const submitCreateOutfit = async (userId: string, outfitItemIds: string[], attributes: Attribute[]) => {
        const attributesForForm = attributes.map(attr => `${attr.key}=${attr.value}`).join('&');
        let postData = new URLSearchParams({
            "items": JSON.stringify(outfitItemIds),
            "attributes": JSON.stringify(attributes)
        });
        try {
            const response = await axiosInstance.post(`/api/users/${userId}/outfits/`, postData.toString(), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });

            return response.data;
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setUser(await apiService.fetchUserModel());
        }
    }

    const deleteOutfit = async (userId: string, outfitId: string) => {
        try {
            const response = await axiosInstance.delete(`/api/users/${userId}/outfits/${outfitId}/`);
            return response.data;
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setUser(await apiService.fetchUserModel());
        }
    }

    const saveOutfit = async (userId: string, outfit: Outfit) => {
        try {
            const method = outfit.id ? 'put' : 'post';
            const url = outfit.id
                ? `/api/users/${userId}/outfits/${outfit.id}/`
                : `/api/users/${userId}/outfits/`;

            const transformedOutfit = {
                ...outfit,
                arrangedItems: outfit.arrangedItems?.map(item => ({
                    itemId: item.id,
                    x: item.x,
                    y: item.y,
                    zIndex: item.zIndex,
                    width: item.width,
                    height: item.height,
                    rotation: item.rotation
                }))
            };

            const response = await axiosInstance({
                method,
                url,
                data: JSON.stringify(transformedOutfit),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            console.error(`Error: ${error}`);
            throw error;
        } finally {
            setUser(await apiService.fetchUserModel());
        }
    };

    const saveOutfitCompositeImage = async (userId: string, outfitId: string, dataUrl: string, onSuccess: (response: any) => void) => {

        const formData = new FormData();

        const response = await fetch(dataUrl);
        const blob = await response.blob();
        formData.append('image', blob, 'image.png');
        try {
            axiosInstance.post(`/api/users/${userId}/outfits/${outfitId}/composite_image/`, formData)
                .then(response => {
                    onSuccess(response.data)
                })
                .catch(error => {
                    console.error('Error submitting composite image:', error);
                });

        } catch (error) {
            console.error(`Error: ${error}`);
            throw error;
        } finally {
            setUser(await apiService.fetchUserModel());
        }

    }

    const generateTestCloset = async (userId: string) => {
        await axiosInstance.post(`/api/users/${userId}/gen_data/`)
        setUser(await apiService.fetchUserModel());
    }

    const resetCloset = async (userId: string) => {
        await axiosInstance.post(`/api/users/${userId}/reset_closet/`)
        setUser(await apiService.fetchUserModel());
    }

    // Return an object containing all API service functions
    return {
        fetchUserModel,
        initLookupData,
        fetchColors,
        fetchItemTypes,
        mapUserModelFromJson,
        mapItems,
        mapAttributes,
        updateItem,
        getUsers,
        getColors,
        getItemTypes,
        getItemSubTypesForItemType,
        submitCreateOutfit,
        deleteOutfit,
        saveOutfitCompositeImage,
        generateTestCloset,
        resetCloset,
        saveOutfit,
        getOutfitAttributeCategories,
        getAllItemTypes,
        preprocessItemImage
    };
};

export const apiService = createApiService(getApiServerUrlBase());
