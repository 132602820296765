export class Base {
    id: string | null; //guid
    created: string | null; //RFC 3339
    updated: string | null; //RFC 3339
    constructor(id: string | null, created: string | null, updated: string | null) {
        this.id = id;
        this.created = created;
        this.updated = updated;
    }
}

export class User extends Base {
    username: string;
    displayName: string;
    items: Item[];
    outfits: Outfit[];

    constructor(id: string, created: string, updated: string, username: string, displayName: string, items: Item[], outfits: Outfit[]) {
        super(id, created, updated);
        this.username = username;
        this.displayName = displayName;
        this.items = items;
        this.outfits = outfits
    }
}

export class Item {
    [key: string]: any;
    image: Image;
    attributes: Attribute[];

    //derived attributes
    location: string | null;
    color: string[] | null;
    itemType: string | null;
    itemSubType: string | null;

    constructor(id: string, attributes: Attribute[], image: Image) {
        this.id = id;
        this.attributes = attributes;
        this.image = image;

        const findAttribute = (key: string) => {
            const attribute = attributes.find(attr => attr.key === key);
            return attribute ? attribute.value : null;
        };

        const findAllAttributes = (key: string) => {
            const matchedAttributes = this.attributes.filter(attr => attr.key === key);
            return matchedAttributes.length > 0 ? matchedAttributes.map(attr => attr.value) : [];
        };

        this.location = findAttribute('location');
        this.color = findAllAttributes('color');
        this.itemType = findAttribute('itemType');
        this.itemSubType = findAttribute('itemSubType');
    }
}

export class Attribute {
    key: string;
    value: string;
    constructor(key: string, value: string) {
        this.key = key;
        this.value = value;
    }
}
//exported as we might reuse it in the future, and generally will point to a common CDN
export class Image {
    originalImage: ImageSize;
    constructor(originalImage: ImageSize) {
        this.originalImage = originalImage;
    }
}
//Pretty common to have a single image instance, but could have multiple for different sizes
//Depop does 150x150,210x210,320x320,480x480,640x640,960x960,1280x1280
//Pinterest does 150x140,400x300,600x,1200x
export class ImageSize {
    width: number; //>100
    height: number; //>100
    url: string;
    constructor(width: number, height: number, url: string) {
        this.width = width;
        this.height = height;
        this.url = url;
    }
}

export interface ItemCategory {
    item: Item;
    score: number;
}

export interface ArrangedItem extends Item {
    x: number;
    y: number;
    zIndex: number;
    width: number;
    height: number;
    rotation: number;
}

export class Outfit {
    id: string | null;
    attributes: Attribute[] | null;
    compositeImageUrl: string | null;
    arrangedItems: ArrangedItem[] | null;

    constructor(
        id: string,
        attributes: Attribute[],
        compositeImageUrl: string | null,
        arrangedItems: ArrangedItem[] | null = null
    ) {
        this.id = id;
        this.attributes = attributes;
        this.compositeImageUrl = compositeImageUrl;
        this.arrangedItems = arrangedItems;
    }
}
